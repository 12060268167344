<template>
  <div class="upload-container">
    <h3>Upload Rekapitulasi MU</h3>
    <form @submit.prevent="submit">
      <div
        class="drop-zone"
        :class="{ 'drag-over': isDragging }"
        @dragover.prevent="isDragging = true"
        @dragleave.prevent="isDragging = false"
        @drop.prevent="handleDrop"
        @click="triggerFileInput"
      >
        <input
          type="file"
          accept=".xlsx, .csv"
          class="file-input"
          @change="handleFileChange"
          ref="fileInput"
        />
        <i class="fas fa-cloud-upload-alt"></i>
        <p>{{ dropMessage }}</p>
      </div>
      <div class="d-flex justify-content-end mt-3">
        <b-button
          variant="primary-1"
          :disabled="loading"
          type="submit"
          class="submit-btn"
        >
          <b-spinner small v-if="loading" class="mr-1"></b-spinner>
          <span>{{ loading ? "Uploading..." : "Upload" }}</span>
        </b-button>
      </div>
    </form>
    <b-toast
      v-model="alert.show"
      :title="alert.type === 'success' ? 'Sukses' : 'Error'"
      :variant="alert.type"
      solid
    >
      {{ alert.message }}
    </b-toast>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import mobilUnit from "../../../api/laporan/mobilUnitAPI";
import { BButton, BToast, BSpinner } from "bootstrap-vue";

const file = ref(null);
const loading = ref(false);
const isDragging = ref(false);
const fileInput = ref(null);

const alert = ref({
  message: "",
  type: "",
  show: false,
});

const dropMessage = computed(() =>
  file.value ? file.value.name : "Klik atau seret file ke sini untuk memilih"
);

const handleFileChange = (event) => {
  file.value = event.target.files[0];
};

const handleDrop = (event) => {
  isDragging.value = false;
  file.value = event.dataTransfer.files[0];
};

const triggerFileInput = () => {
  fileInput.value.click();
};

const setAlert = (message, type) => {
  alert.value = {
    message,
    type,
    show: true,
  };
};

const submit = async () => {
  if (!file.value) {
    setAlert("Silakan pilih file terlebih dahulu.", "danger");
    return;
  }

  loading.value = true;
  const formData = new FormData();
  formData.append("file", file.value);

  try {
    const response = await mobilUnit.uploadExcelReport(formData);
    setAlert(response.data.message, "success");
  } catch (error) {
    setAlert(error.response?.data?.message || "Upload gagal", "danger");
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
.upload-container {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.drop-zone {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.drop-zone:hover,
.drop-zone.drag-over {
  border-color: #007bff;
  background-color: #f8f9fa;
}

.file-input {
  display: none;
}

.drop-zone i {
  font-size: 3rem;
  color: #007bff;
  margin-bottom: 1rem;
}

.submit-btn:hover:not(:disabled) {
  background-color: #0056b3;
}

.submit-btn:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}
</style>
